export const Config = {
  // 溯源 api 地址
  // 测试环境：https://demo.smartracing.cn
  // 正式环境：https://api.smartracing.cn
  baseApiUrl: 'https://demo.smartracing.cn',

  // 益农 api 地址
  // 测试环境：http://testapi.gdynyp.com
  // 正式环境：https://api.gdynyp.com
  shopApiUrl: 'https://testapi.gdynyp.com',

  // 项目 ID
  clientID: '5d23ea8881c1a1a03b348f03'
}
