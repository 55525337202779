import axios from 'axios'
import { Toast } from 'vant'
import { Config } from '../utils/config'

// axios 相关配置
const instance = axios.create({
  timeout: 10000
})
instance.interceptors.request.use(config => {
  Toast.loading({
    message: '加载中...',
    duration: 0,
    forbidClick: true
  })
  return config
})
instance.interceptors.response.use(
  response => {
    Toast.clear()
    return response
  },
  error => {
    Toast('网络异常')
    return Promise.reject(error)
  }
)

// 定义请求方法
function $get(url, data = {}) {
  return instance.get(url, { params: data })
}
function $post(url, data = {}) {
  return instance.post(url, data)
}

class Services {
  code = {
    verify: data => $post(`${ Config.baseApiUrl }/v1/users1/auth/verify`, data), // 获取验证码
  }
  users = {
    login: data => $post(`${ Config.baseApiUrl }/v1/users1/auth/register`, data), // 登录
    details: data => $get(`${ Config.baseApiUrl }/v1/users1/profiles/me`, data), // 获取档案信息
    profiles: data => $get(`${ Config.baseApiUrl }/v1/users1/markets/shops/${ data.id }`) // 根据id获取用户信息
  }
  orderTracking = {
    profiles: data => $get(`${ Config.baseApiUrl }/v1/users1/profiles/get-profile`, data), // 根据商户号获取商家信息
    findOne: must => $get(`${ Config.baseApiUrl }/v1/users1/markets/shops/` + must.shopId), // 获取店铺详情
    handmadeTrace: data => $get(`${ Config.baseApiUrl }/v1/stocks/shop`, data), // 获取手工溯源店铺的商品列表
    images: (data) => $get(`${ Config.baseApiUrl }/v1/catalog/imgs`, data), // 根据 ids 批量获取图片
    orderTrace: data => $get(`${ Config.baseApiUrl }/v1/statistics-yz/stock/shop`, data), // 获取订单溯源商品列表
    handmadeTraceDetails: data => $get(`${ Config.baseApiUrl }/v1/stocks/new-traceability`, data), // 手工溯源商品详情
    orderTraceDetails: data => $get(`${ Config.baseApiUrl }/v1/statistics-yz/stock/traceability`, data), // 订单溯源商品详情
  }
  products = {
    stockItems: data => $get(`${ Config.shopApiUrl }/goods/search/merchantGoodsSpec`, data), // 获取商家库存商品
    saleItems: data => $get(`${ Config.shopApiUrl }/sazs/wholesaler/goods`, data) // 在售商品列表
  }
  orders = {
    add: data => $post(`${ Config.baseApiUrl }/v1/nhpay/wholesale/submitOrder`, data), // 生成订单
    details: data => $post(`${ Config.baseApiUrl }/v1/nhpay/wholesale/orderDetails`, data) // 获取订单详情
  }
  tracing = {
    register: {
      // items: data => $get(`${ Config.shopApiUrl }/seller-register`, data), // 获取商品登记列表
      items: data => $get(`${ Config.shopApiUrl }/sazs/wholesaler/goods/infos`, data), // 获取商品登记列表
      details: data => $get(`${ Config.shopApiUrl }/sazs/wholesaler/goods/infos/goods/${ data.id }`) // 获取商品登记详情
    }
  }
  payments = {
    pay: data => $post(`${ Config.baseApiUrl }/v1/nhpay/nhpay/doPayJson`, data) // 调起农行支付
    // pay: data => $post(`http://192.168.3.39:8080/nhpay/doPayJson`, data)
  }
  userOrder = {
    list: data => $post(`${Config.baseApiUrl}/v1/nhpay/wholesale/orderList`,data),
    detail: data => $post(`${ Config.baseApiUrl }/v1/nhpay/wholesale/orderDetails`,data)
  }
}

export const services = new Services()
