<template>
  <div>
    <div class="index-wrapper" v-if="token">
      <!--    <b-->
      <!--      class="user-info-btn"-->
      <!--      @click="toUser">-->
      <!--      个人中心-->
      <!--    </b>-->

      <div class="index-main">
        <div class="pay-item">
          <h3>商户：{{ wholesalerInfo.name }}</h3>
          <p class="explain-text">请输入支付金额</p>
          <div class="pay-input">
            <i>¥</i>
            <s class="input-text" v-html="total"></s>
            <em></em>
          </div>
        </div>
      </div>

      <!-- 支付键盘 -->
      <div class="popup-box st-body">
        <div class="key-board">
          <ul class="key-row">
            <li v-for="(item, index) in keyboards" :key="index" @touchstart.stop="touchstart(item)" class="key-item">
              {{item}}
            </li>
            <li class="key-item key-del" @click="deleteNumber">
            </li>
          </ul>

          <!-- <ul class="key-row">
            <li
              class="key-item key-del"
              @click="deleteNumber">
            </li>
            <li
              class="key-item key-pay"
              :class="btnState ? 'active' : ''"
              @click="submit">
              支付
            </li>
          </ul> -->
        </div>

      </div>
      <div class="key-pay" :class="btnState ? 'active' : ''" @click="submit">
        支付
      </div>

    </div>
  </div>

</template>

<script>
import { Toast } from 'vant'
import { mapState } from 'vuex'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: '',
  data() {
    return {
      wholesalerInfo: {},
      productItems: [], // 商品列表
      registerItems: [], // 商品溯源信息列表

      keyboards: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0'],
      total: '',
      btnState: false,
      token: '',
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },
  watch: {
    total(value) {
      let reg = /^[+-]?[1-9]?[0-9]*\.[0-9]*$/
      let reg1 = /^[0-9]*[1-9][0-9]*$/
      if ((!reg1.test(value) && !reg.test(value) && value !== '0') || value * 1 < 0.01) {
        this.btnState = false
        return
      }
      this.btnState = true
      return
    },
  },
  created() {
    const local = window.location.href

    let storage_token = JSON.parse(localStorage.getItem('storage_token'))
    let query_token = getPara('token')
    if (storage_token) {
      let date = new Date().getTime()
      //7天过期
      if (date - storage_token.startTime > 7 * 24 * 3600 * 1000) {
        //缓存过期，清除缓存
        localStorage.removeItem('storage_token')
        window.location =
          'https://app.js.abchina.com/jiangsu/suzhou/QRShare/CommPages/InitPayerInfo.aspx?url=' +
          encodeURIComponent(local)
      } else {
        this.token = storage_token.value
      }
    } else if (query_token) {
      this.token = query_token
      let item = { value: query_token, startTime: new Date().getTime() }
      localStorage.setItem('storage_token', JSON.stringify(item))
    } else {
      window.location =
        'https://app.js.abchina.com/jiangsu/suzhou/QRShare/CommPages/InitPayerInfo.aspx?url=' +
        encodeURIComponent(local)
    }

    function getPara(name) {
      let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      let r = window.location.hash.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    }
  },
  mounted() {
    this.$store.dispatch('setWholesalerId', this.$route.query.id)
    this.init()
  },
  methods: {
    // 初始化
    init() {
      // if (this.userInfo && this.userInfo.id) {
      //   let wholesalerId = this.$route.query.id
      //   wholesalerId
      //     ? this.getWholesalerInfo(wholesalerId)
      //     : Toast('查询不到商家信息')
      // } else {
      //   this.$router.push({
      //     name: 'Login'
      //   })
      // }
      let wholesalerId = this.$route.query.id
      wholesalerId ? this.getWholesalerInfo(wholesalerId) : Toast('查询不到商家信息')
    },
    // 获取批发商基本信息
    getWholesalerInfo(wholesalerId) {
      this.$api.users
        .profiles({
          id: wholesalerId,
        })
        .then((res) => {
          if (res.data.success) {
            this.wholesalerInfo = res.data.result
            if (this.wholesalerInfo.e_market_shop_id) {
              this.getRegisterItems(this.wholesalerInfo.e_market_shop_id)
              this.getProductItems(this.wholesalerInfo.e_market_shop_id)
            }
          } else {
            Toast('查询不到商家信息')
          }
        })
    },
    // 获取上架商品SKU
    getProductItems(shopId) {
      this.$api.products
        .saleItems({
          flag: 1,
          page_no: 1,
          page_size: 1000,
          seller_id: shopId,
        })
        .then((res) => {
          if (res.status === 200) {
            let items = res.data.data
            // 格式化商品列表
            this.productItems = _.map(items, (item) => {
              let skus = _.map(item.sku_list, (sku) => {
                return {
                  price: sku.price,
                  unit: sku.spec_list[0].spec_name,
                  number: sku.spec_list[1].spec_value,
                }
              })
              return {
                id: item.goods_id,
                name: item.goods_name,
                price: skus[0].price || 0,
                unit: skus[0].unit || '',
                image: item.goods_gallery_list[0].small,
                skus: skus,
                number: skus[0].number || 0,
                registerInfo: item.shop_registration_vo,
              }
            })
          } else {
            Toast('查询不到相关商品')
          }
        })
    },
    // 根据店铺ID获取商品进场列表
    getRegisterItems(id) {
      this.$api.tracing.register
        .items({
          seller_id: id,
        })
        .then((res) => {
          if (res.status === 200) {
            this.registerItems = res.data
          }
        })
    },

    // 输入金额
    touchstart(value) {
      if (this.validatePrice(value)) {
        this.total = this.total + value
      }
    },
    // 金额校验
    validatePrice(key) {
      let checkMoney = this.total.split('')
      if (
        (checkMoney.indexOf('.') > -1 && key === '.') ||
        (checkMoney.length === 0 && key === '.') ||
        (checkMoney[0] === '0' && checkMoney.length === 1 && key !== '.') ||
        ((this.total + key).split('.')[1] && (this.total + key).split('.')[1].length > 2)
      ) {
        return false
      }
      return true
    },
    // 删除金额
    deleteNumber() {
      this.total = this.total.substring(0, this.total.length - 1)
    },

    // 提交订单
    submit() {
      if (this.btnState) {
        let items = _.map(this.productItems, (item) => {
          let n = _.findIndex(
            this.registerItems,
            (registerItem) => registerItem.goods_id === item.id
          )
          return {
            goodsId: item.id,
            goodsName: item.name, // 商品名称
            goodsImage: item.image, // 商品图片
            goodsPrice: Number(item.price), // 商品单价
            buyNumber: 0, // 商品数量
            unit: item.unit, // 规格名称

            // 溯源信息
            time: this.registerItems[n].create_time
              ? moment.unix(this.registerItems[n].create_time).format('YYYY-MM-DD HH:mm:ss')
              : '',
            goodsType: this.registerItems[n].kind, // 产品种类
            licensePlateNumber: this.registerItems[n].license_plate_number, // 运输车牌号
            placeOfOrigin: this.registerItems[n].place_of_origin, // 产地
            numberOfBranches: this.registerItems[n].number_of_branches, // 进场数量
            inspectionAndQuarantine: this.registerItems[n].inspection_and_quarantine, // 产地检疫证号
            inspectionAndQuarantineList: this.registerItems[n].inspection_and_quarantine_list, // 产地检疫证号图片
            bno: this.registerItems[n].sn, // 批次号
            productMajorType: this.registerItems[n].type, // 产品大类
          }
        })
        let orderInfo = {
          merchId: this.$route.query.id, // 批发商id
          orderMoney: 0, // 总金额
          userId: '',
          buyerMobile: '',
          data: items, // 商品及溯源信息
        }

        this.addOrder(orderInfo)
        // this.userInfo && this.userInfo.id
        //   ? this.addOrder(orderInfo)
        //   : this.$router.push({
        //     name: 'Login'
        //   })
      }
    },
    // 生成订单
    addOrder(orderInfo) {
      orderInfo.userId = this.userInfo.id
      orderInfo.buyerMobile = this.userInfo.mobile
      this.$api.orders.add(orderInfo).then((res) => {      
        res.data.success && res.data.map.orderId
          ? this.payment(res.data.map.orderId)
          : Toast('订单提交失败')
      })
    },
    // 支付
    payment(orderId) {
      if (this.total > 0) {
        this.$api.payments
          .pay({
            merch_id: this.wholesalerInfo.merch_id,
            // merch_id: '709080000000653',
            // merch_id: '910120000005283',
            orderType: 2,
            amount: this.moneyFormat(this.total),
            orderId: orderId,
            token: this.token,
          })
          .then((res) => {
            res.status === 200 && res.data.scanCode
              ? (window.location.href = res.data.scanCode)
              : Toast('订单提交失败，请重新支付')
          })
      } else {
        Toast('请输入支付金额')
      }
    },

    toUser() {
      _.isEmpty(this.userInfo)
        ? this.$router.push({
            name: 'Login',
          })
        : this.$router.push({
            name: 'User',
          })
    },
    // 跳转进场信息页
    registerInfo(item) {
      this.$router.push({
        name: 'TracingRegister',
        query: {
          merchId: this.$route.query.id,
          productId: item.id,
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
@import './index';
</style>
